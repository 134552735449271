import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: resolve => require(['@/views/login.vue'], resolve)
  },
  {
    path: '/deskTop',
    component: resolve => require(['@/views/deskTop/deskTop.vue'], resolve),
	children:[
		{
		    path: 'home',
		    component: resolve => require(['@/views/deskTop/home.vue'], resolve),
		}
	]
  },
  {
    path: '/member',
    component: resolve => require(['@/views/deskTop/deskTop.vue'], resolve),
  	children:[
  		{
  		    path: 'memberList',
  		    component: resolve => require(['@/views/member/memberList.vue'], resolve),
  		},
		{
		    path: 'memberAddress',
		    component: resolve => require(['@/views/member/memberAddress.vue'], resolve),
		},
		{
		    path: 'memberTag',
		    component: resolve => require(['@/views/member/memberTagList.vue'], resolve),
		},
		{
		    path: 'memberScore',
		    component: resolve => require(['@/views/member/memberScore.vue'], resolve),
		},
		{
		    path: 'shoppingHistory',
		    component: resolve => require(['@/views/member/shoppingHistory.vue'], resolve),
		},
		{
		    path: 'historyMemberOrder',
		    component: resolve => require(['@/views/member/historyMemberOrder.vue'], resolve),
		},
  	]
  },
  {
    path: '/staff',
    component: resolve => require(['@/views/deskTop/deskTop.vue'], resolve),
  	children:[
  		{
  		    path: 'staffList',
  		    component: resolve => require(['@/views/staff/staffList.vue'], resolve),
  		}
  	]
  },
  {
    path: '/saletools',
    component: resolve => require(['@/views/deskTop/deskTop.vue'], resolve),
  	children:[
  		{
  		    path: 'couponList',
  		    component: resolve => require(['@/views/saletools/couponList.vue'], resolve),
  		},
		{
		    path: 'setMemberScore',
		    component: resolve => require(['@/views/saletools/setMemberScore.vue'], resolve),
		},
		{
		    path: 'scoreWater',
		    component: resolve => require(['@/views/saletools/scoreWater.vue'], resolve),
		},
  	]
  },
  {
    path: '/goods',
    component: resolve => require(['@/views/deskTop/deskTop.vue'], resolve),
  	children:[
  		{
  		    path: 'goodsList',
  		    component: resolve => require(['@/views/goods/goodsList.vue'], resolve),
  		},
		{
		    path: 'goodsClass',
		    component: resolve => require(['@/views/goods/goodsClass.vue'], resolve),
		}
  	]
  },
  {
	  path: '/globle',
	  component: resolve => require(['@/views/deskTop/deskTop.vue'], resolve),
	  children:[
	  	{
	  	    path: 'updateUpwd',
	  	    component: resolve => require(['@/views/globle/updateUpwd.vue'], resolve),
	  	},
	  	{
	  	    path: 'uplodeImg',
	  	    component: resolve => require(['@/views/globle/uplodeImg.vue'], resolve),
	  	},
		{
		    path: 'config',
		    component: resolve => require(['@/views/globle/config.vue'], resolve),
		},
		{
		    path: 'h5QrCode',
		    component: resolve => require(['@/views/globle/h5QrCode.vue'], resolve),
		},
		{
		    path: 'shopCheck',
		    component: resolve => require(['@/views/globle/shopCheck.vue'], resolve),
		}
	  ]
  }
]

// 解决报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};




const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
