<template>
  <div id="app">
	<router-view />		 
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
     
    }
  },
  methods: {
    
  }
}
</script>


<style>
  @import "assets/css/common.css";
</style>
