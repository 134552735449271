import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUi from 'element-ui'
import Axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css'

import utils from './common/js/utils.js'
import verify from './common/js/verify.js'
import mixin from '@/common/js/mixin.js'
import Filter from '@/common/js/filter.js'
import store from './store'
import bigImg from "@/components/bigImg.vue" 

Vue.component('bigImg', bigImg)

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Object.keys(Filter).forEach(key => Vue.filter(key, Filter[key]))
Vue.mixin(mixin)

Vue.use(ElementUi,{
	size:'small'
})

if(location.href.indexOf('xucheng')!=-1){
  Vue.prototype.$domain='https://apishopsys.xucheng.top/index.php'
}else{
  Vue.prototype.$domain = 'http://192.168.1.3::81/index.php'
}

// 拖拽
import VueDND from 'awe-dnd'
Vue.use(VueDND)

Vue.prototype.axios = Axios;
const obj = {
  utils,
  verify
}

for (const key in obj) {
  Object.defineProperty(Vue.prototype, `$${key}`, {
    get: () => obj[key]
  })
}
NProgress.configure({
easing: 'ease', // 动画方式
speed: 600, // 递增进度条的速度
showSpinner: true, // 是否显示加载ico
trickleSpeed: 200, // 自动递增间隔
minimum: 0.3 // 初始化时的最小百分比
})

router.beforeEach((to,from,next) => {
  NProgress.start() 
  next()
})
router.afterEach(() => {
  NProgress.done()
})


Vue.config.productionTip = false

Vue.prototype.$post = function (url, data, callback) {
    var _this = this
    let cid = sessionStorage.getItem('cid')
    let token = sessionStorage.getItem('token')
    if(!token){
      return  _this.$router.push({path:'/'})
    }
    data.token = token
    data.cid=cid
    if (url.indexOf('http') == -1) {
        url = this.$domain + url
    }
	const loading = this.$loading({
	  text: '加载中',
	  background: 'rgba(255, 255, 255, 0.1)',
	  spinner: 'el-icon-loading',
	  customClass: 'loadingclass'
	})
     _this.axios.post(url, data).then(function (res) {
        var call = callback || function (res) {}
        if(res.data.code==401){
          _this.$router.push('/')
        }else if(res.data.code==400){
          ElementUi.Message({
			  message:res.data.msg,
			  type:'error'
		  })
        }else{
			 call(res)
		}
		 loading.close()
     })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
