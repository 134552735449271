<template>
	<div>
	  <el-image
		:style="{width:width,height:height}"
	    :src="url" 
	    :preview-src-list="[url]">
	  </el-image>
	</div>
</template>

<script>
	export default {
		props:{
			furl:String,
			width:{
				type:String,
				default:'80px'
			},
			height:{
				type:String,
				default:'80px'
			}
		},
		created(){
			this.url = this.furl
		},
	    data() {
	      return {
	        url: '',
	      }
	    }
	  }
</script>

<style>
</style>