
// 验证字符串中是否存在汉字
function isChina(val) {
  let reg = new RegExp('[\\u4E00-\\u9FFF]+','g')
  if (reg.test(val)) {
    // 含有中文字符
    return true
  } else {
    return false
  }
}

// 判断是否是图片
function  isImage(param) {
  let isArr = ['png', 'jpg', 'jpeg', 'pcx', 'psd', 'tiff', 'bmp', 'webp']
  function sliceStr(str) {
    const arr = str.split('.')
    const strs = arr[arr.length - 1]
    return isArr.includes(strs.toLocaleLowerCase())
  }
  if (Array.isArray(param)) {
    for (let item of param) {
      if (!sliceStr(item)) {
        return false
      } else {
        continue
      }
    }
    return true
  } else {
    return sliceStr(param)
  }
}



//判断输入的是不是手机号

function isPhone(phone){
  if (!phone || phone.length < 11) return false
  var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  if (!myreg.test(phone)) {
    return false;
  } else {
    return true;
  }
}

function isName(name) {
  if (!name) return false
  let reg = /[0-9]/
  console.log(reg.test(name), '-- 65 --')
  if (reg.test(name)) {
    return false
  } else {
    return true
  }
}



//截取字符串最后一位
function sliceLastStr(str){

      var len = str.length
      str = str.slice(0,len-1)

      return str

}


// 格式化时间
function  formatData(date) {
  if (!date) return false
  date = new Date(date);
  let Y = date.getFullYear()
  let M = date.getMonth() + 1
  let D = date.getDate()
  let h = date.getHours()
  let m = date.getMinutes()
  let s = date.getSeconds()
  function Time(time) {
    return time > 9 ? time : '0' + time
  }
  return `${Y}-${Time(M)}-${Time(D)} ${Time(h)}:${Time(m)}:${Time(s)}`
}

//数组数据处理
function oldArray(val){
	return JSON.parse(JSON.stringify(val))
}

//获取序号
function returnNumber(page,index,pageSize=10){
   var page = page*1 - 1
   var index = index++
   var str
   if(index==pageSize){
      str = (page+1)*pageSize
   }else{
      str = page*pageSize + index
   }
   return str+1
}
//判断是不是JSON
function isJSON_test(str) {
        if (typeof str == 'string') {
            try {
                var obj=JSON.parse(str);
                return true;
            } catch(e) {
                return false;
            }
        }else{
			return false;
		}
        
 }
 
 function hasClass(ele, cls) {
   return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
 }
 /**
  * Add class to element
  * @param {HTMLElement} elm
  * @param {string} cls
  */
  function addClass(ele, cls) {
   if (!hasClass(ele, cls)) ele.className += ' ' + cls
 }
 
 /**
  * Remove class from element
  * @param {HTMLElement} elm
  * @param {string} cls
  */
  function removeClass(ele, cls) {
   if (hasClass(ele, cls)) {
     const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
     ele.className = ele.className.replace(reg, ' ')
   }
 }
 
 function defaultImg(header_img){
 	if(!header_img){
 	    header_img = 'https://apishopsys.xucheng.top/uplode/timg.jpg'
		return header_img
 	}
 	// return 'https://sinacloud.net/header-img/' + header_img
	return '/imageHeader/url/' + header_img
 }　

export default {
  defaultImg,	
  isJSON_test,
  returnNumber,
  oldArray,
  isChina,
  isImage,
  isPhone,
  isName,
  sliceLastStr,
  formatData,
  addClass,
  removeClass
}
