const Two = (val) => (+val).toFixed(2)
/**
 * @param {Object} time - 时间
 * @param {Object} type
 */
function getTime(time, type) {
	if (/\-/.test(time)) {
		time = time.replace(/\-/g, '/')
	}
	let date = new Date(time)
	let currentDate = new Date()
	let Tm = getCurrentTime(date)
	let currentTime = getCurrentTime(currentDate)
	if (!type) {
		if (currentTime[0] == Tm[0]) {
			if (currentTime[1] == Tm[1]) {
				if (currentTime[2] == Tm[2]) {
					return `${Tm[3]}:${Tm[4]}`
				} else if (currentTime[2] - Tm[2] == 1) {
					return '昨天'
				} else if (currentTime[2] - Tm[2] < 7) {
					return week(date)
				} else {
					return `${Tm[1]}-${Tm[2]}`
				}
			} else {
				return `${Tm[1]}-${Tm[2]}`
			}
		} else {
			return `${Tm[0]}-${Tm[1]}-${Tm[2]}`
		}
	} else {
		if (currentTime[0] == Tm[0]) {
			if (currentTime[1] == Tm[1]) {
				if (currentTime[2] == Tm[2]) {
					return `${Tm[3]}:${Tm[4]}`
				} else if (currentTime[2] - Tm[2] == 1) {
					return `昨天 ${Tm[3]}:${Tm[4]}`
				// } else if (currentTime[2] - Tm[2] < 7) {
				// 	return week(date)
				} else {
					return `${Tm[1]}-${Tm[2]} ${Tm[3]}:${Tm[4]}`
				}
			} else {
				return `${Tm[1]}-${Tm[2]}`
			}
		} else {
			return `${Tm[0]}-${Tm[1]}-${Tm[2]}`
		}
	}
}

function week (date) {
	let weekArr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
	let timeArr = getCurrentTime(date)
	let hisDays = date.getDay()
	let cDay = new Date().getDay()
	let timeDif = cDay == 0 ? 7 - hisDays : cDay - hisDays
	if (timeDif < 0 || hisDays == 0) {
		return `${timeArr[1]}-${timeArr[2]}`
	} else {
		return weekArr[hisDays]
	}
}

 

function getCurrentTime(data) {
	let Y = data.getFullYear(),
			M = data.getMonth() + 1,
			D = data.getDate(),
			h = data.getHours(),
			m = data.getMinutes(),
			s = data.getSeconds();
	function fromData(time) {
		return time > 9 ? time : `0${time}`
	}
	return [fromData(Y), fromData(M), fromData(D), fromData(h), fromData(m), fromData(s)]
}

function formatTime (time, type=1) {
	let getTime = getCurrentTime(new Date(time * 1000))
	if (type == 1) {
		return `${getTime[0]}-${getTime[1]}-${getTime[2]} ${getTime[3]}:${getTime[4]}:${getTime[5]}`
	} else if (type == 2) {
		return `${getTime[0]}-${getTime[1]}-${getTime[2]}`
	}
}



export default {
	Two,
	getTime,
	formatTime
}
