// 验证手机号
function isPhone(value) {
	console.log(value, '----- verify --')
	if (value.length < 11) return false
	if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
		return false;
	} else {
		return true;
	}
}
// 验证密码
function isPwd(value) {
	if (value.length < 6) {
		return false;
	} else {
		return true;
	}
}
// 验证邮箱
function isEmail(value) {
	if (!(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value))) {
		return false;
	} else {
		return true;
	}
}

// 验证字符串中是否存在汉字
function isChina(val) {
	let reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g')
	if (reg.test(val)) {
		// 含有中文字符
		return true
	} else {
		return false
	}
}



export default {
	isPhone,
	isPwd,
	isEmail,
	isChina
}
