export default {
	data () {
		return {
			// goodsImgUrl:'https://my-img.sinacloud.net/',
			 goodsImgUrl:'/image/url/',
			 // imgUrl:'https://shop-info.sinacloud.net/',//商户审核
			 imgUrl:'/shopinfo/url/',//商户审核
		}
	},
	methods: {
		//返回优惠券名字
		returnCouponName(row){
			let type = row.type
			let str
			if(type==1){
				str = `满${row.money}元优惠${row.discount_money}元`
			}
			if(type==2){
				str = `满${row.money}元打${row.rebate}折`
			}
			return str
		},
	}
}